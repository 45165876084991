<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Brand logo-->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            fluid
            :src="logoPreview"
            alt="Logo"
            class="desktop-logo"
            style="margin-bottom: 20px; width: 100px;"
          />
        </b-link>

        <!-- Forget Password-->
        <div class="d-flex justify-content-center">
          <b-img
            fluid
            :src="logoPreview"
            alt="Logo"
            style="margin-bottom: 20px; width: 100px;"
            class="mobile-logo space"
          />
        </div>
        <b-card-title
          title-tag="h2"
          class="space font-weight-bold mb-1"
        >
          Seja bem-vindo
        </b-card-title>
        <b-card-text class="mb-2">
          <b>Alteração de senha:</b> caso você tenha um email válido cadastrado em nossa plataforma você receberá um link para alteração de sua senha.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="validationForm()"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="login-email"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="usuario@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit buttons -->
            <b-overlay
              :show="loading"
              rounded="sm"
            >
              <b-button
                type="submit"
                :disabled="emailDisabled"
                variant="primary"
                block
              >
                Enviar
              </b-button>
              <b-overlay />
            </b-overlay>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2 backText">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar
          </b-link>
        </b-card-text>
        <!-- /Login-->
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BLink,
  BFormGroup,
  BFormInput,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt'
import { required, email } from '@validations'

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      email: '',
      required,
      loading: false,
      emailDisabled: true,
      logo: [],
      banner: [],
      logoPreview: null,
      bannerPreview: null,
      name: '',
      description: '',
      isBusy: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    userEmail() {
      const valid = String(this.userEmail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )

      if (valid) {
        this.emailDisabled = false
      } else {
        this.emailDisabled = true
      }
    }
  },
  methods: {
    getPersonalizacao() {
      // Verificar se a logo e o banner já estão no LocalStorage
      const logo = localStorage.getItem('logo');
      const banner = localStorage.getItem('banner');

      if (logo && banner && logo !== "undefined" && banner !== "undefined") {
        // Se existirem no LocalStorage, atribuir os valores diretamente às variáveis
        this.logoPreview = logo;
        this.bannerPreview = banner;
        this.name = localStorage.getItem('name');
        this.description = localStorage.getItem('description');
      } else {
        // Caso contrário, fazer a solicitação GET e salvar os valores no LocalStorage
        axios.get(`${process.env.VUE_APP_API}/personalizar`)
          .then(response => {
            console.log(response.data);
            this.logoPreview = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.logo}`;
            this.bannerPreview = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.banner}`;
            this.name = response.data.response.name;
            this.description = response.data.response.description;

            // Salvar os valores no LocalStorage para uso futuro
            localStorage.setItem('logo', this.logoPreview);
            localStorage.setItem('banner', this.bannerPreview);
            localStorage.setItem('name', this.name);
            localStorage.setItem('description', this.description);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.forgetPassword()
        }
      })
    },
    async forgetPassword() {
      this.loading = true
      const forgetPassword = await useJwt.forgetPassword({ email: this.userEmail })
      // this.userEmail = ''

      if (forgetPassword.data.status === 200) {
        this.$swal({
          title: 'Enviado',
          text: 'Link enviado para o seu e-mail, lembre de conferir a caixa de spam.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        })
        this.$router.push('login')
      } else if (forgetPassword.data.status === 100) {
        //
      } else {
        console.log(forgetPassword.data.errors)
        this.$swal({
          title: 'Ops...',
          text: forgetPassword.data.errors,
          icon: 'danger',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        })
      }
      this.loading = false
    }
  },
  beforeMount() {
    this.getPersonalizacao()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@media (min-width: 0px) and (max-width: 991px) {
  .mobile-logo {
    display: block;
  }
  .desktop-logo {
    display: none;
  }
}
@media (min-width: 992px){
  .desktop-logo {
    display: block;
  }
  .mobile-logo {
    display: none;
  }
}
.mb-2, .mb-1 {
  text-align: center;
}
.space {
  margin-bottom: 20px !important;
}
.backText {
  margin-right: 15px;
}
</style>
